var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section section-page-header"},[_c('div',{staticClass:"m-container"},[_c('h1',[_vm._v(_vm._s(_vm.$t("navigation.favorites")))])])]),_vm._l((_vm.indexHomeData.blocks),function(item,index){return _c('section',{key:index,staticClass:"section section-carousel"},[_c('div',{staticClass:"section-header m-container"},[_c('h2',[_vm._v(_vm._s(item.sections[0].title))])]),_c('div',{staticClass:"carousel-wrapper"},[_c('div',{staticClass:"carousel-container"},[_c('div',{staticClass:"carousel-offset"},[_c('div')]),_vm._l((_vm.localizeFavorites.filter(function (favorite) { return favorite.collections.find(
                function (fitem) { return fitem.isSection &&
                  fitem.collectionId === item.sections[0].id; }
              ); }
            )),function(itm,idx){return _c('div',{key:idx,staticClass:"carousel-item",class:{
              'music-item':
                itm.contentType === 'Album' || itm.contentType === 'Book'
            }},[_c('router-link',{attrs:{"to":{ path: _vm.generateRouteUrl(itm) }}},[_c('figure',{style:({
                  'background-image':
                    'url(' + _vm.replaceCoverImage(itm.favoriteCover) + ')'
                })}),(parseInt(itm.year))?_c('p',[_vm._v(" "+_vm._s(itm.title)+" • "),_c('span',[_vm._v(_vm._s(itm.year))])]):_c('p',[_vm._v(" "+_vm._s(itm.title)+" ")])])],1)}),_c('div',{staticClass:"carousel-item carousel-item-empty",class:{
              'music-item':
                _vm.contentType(item.sections[0]) === 'album' ||
                _vm.contentType(item.sections[0]) === 'Book'
            }},[_c('figure',[_c('em'),_c('figcaption',[_vm._v(" "+_vm._s(_vm.$t("favorites.add_favorites_prefix"))+" "),(_vm.indexSectionLoading === false)?_c('router-link',{staticClass:"more",attrs:{"to":{
                    name: 'MovieList',
                    params: {
                      section: item.sections[0].id,
                      collection: _vm.indexSectionData.find(
                        function (it) { return it.id === item.sections[0].id; }
                      ).collections[0].id
                    }
                  }}},[_vm._v(_vm._s(_vm.$t(("favorites." + (_vm.contentType(item.sections[0]))))))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("favorites.add_favorites_postfix"))+" ")],1)])]),_c('div',{staticClass:"carousel-offset"},[_c('div')])],2)])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }