export default {
  methods: {
    replaceCoverImage(image) {
      return image.replace("{sizeId}", 0).replace("{scale}", 1);
    },
    replacePreloadCoverImage(image) {
      return image.replace("{sizeId}", 3).replace("{scale}", 1);
    },

    generateRouteUrl(item) {
      if (item.contentType === "Serial") {
        return (
          "/" + item.contentType.toLowerCase() + "s/" + item.id + "/episode/0"
        );
      }
      return "/" + item.contentType.toLowerCase() + "s/" + item.id;
    }
  }
};
