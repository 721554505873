<template>
  <transition name="fade">
    <main role="main">
      <meta-title :title="metaTitle"></meta-title>
      <section class="section section-page-header">
        <div class="m-container">
          <h1>{{ $t("navigation.favorites") }}</h1>
        </div>
      </section>
      <section
        class="section section-carousel"

        v-for="(item, index) in indexHomeData.blocks"
        v-bind:key="index"
      >
        <div class="section-header m-container">
          <h2>{{ item.sections[0].title }}</h2>

        </div>

        <div class="carousel-wrapper">
          <div class="carousel-container">
            <div class="carousel-offset"><div></div></div>
            <div
              class="carousel-item"
              :class="{
                'music-item':
                  itm.contentType === 'Album' || itm.contentType === 'Book'
              }"
              v-for="(itm, idx) in localizeFavorites.filter(favorite =>
                favorite.collections.find(
                  fitem =>
                    fitem.isSection &&
                    fitem.collectionId === item.sections[0].id
                )
              )"
              :key="idx"
            >
              <router-link :to="{ path: generateRouteUrl(itm) }">
                <figure
                  v-bind:style="{
                    'background-image':
                      'url(' + replaceCoverImage(itm.favoriteCover) + ')'
                  }"
                />
                <p v-if="parseInt(itm.year)">
                  {{ itm.title }} • <span>{{ itm.year }}</span>
                </p>
                <p v-else>
                  {{ itm.title }}
                </p>
              </router-link>
            </div>
            <div
              class="carousel-item carousel-item-empty"
              :class="{
                'music-item':
                  contentType(item.sections[0]) === 'album' ||
                  contentType(item.sections[0]) === 'Book'
              }"
            >
              <figure>
                <em></em>
                <figcaption>
                  {{ $t("favorites.add_favorites_prefix") }}
                  <router-link
                    v-if="indexSectionLoading === false"
                    class="more"
                    :to="{
                      name: 'MovieList',
                      params: {
                        section: item.sections[0].id,
                        collection: indexSectionData.find(
                          it => it.id === item.sections[0].id
                        ).collections[0].id
                      }
                    }"
                    >{{
                      $t(`favorites.${contentType(item.sections[0])}`)
                    }}</router-link
                  >
                  {{ $t("favorites.add_favorites_postfix") }}
                </figcaption>
              </figure>
            </div>
            <div class="carousel-offset"><div></div></div>
          </div>
        </div>
      </section>
    </main>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import cover from "@/mixins/cover";

export default {
  name: "favorites",
  title() {
    return this.$t("pages.favorites");
  },
  mixins: [cover],
  computed: {
    localizeFavorites() {
      const data = this.index
        .map(value => {
          return value.contents;
        })
        .flat();
      let newFavorites = data.map(item => {
        if (this.favorites.find(itm => itm.id === item.id)) {
          return {
            ...this.favorites.find(itm => itm.id === item.id),
            title: item.title
          };
        }
      });

      console.log(newFavorites);
      return newFavorites;
    },
    ...mapState("favorites", {
      favorites: state => state.favorites,
      index: state => state.index,
      advertiseData: state => state.advertise,
      indexLoading: state => state.indexLoading
    }),
    ...mapState("home", {
      indexHomeData: state => state.index,
      indexHomeLoading: state => state.indexLoading
    }),
    ...mapGetters("favorites", {
      getAudio: "GET_AUDIO",
      getMovie: "GET_MOVIE",
      getSerial: "GET_SERIAL"
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    })
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex(this.favorites);
        }
      },
      immediate: true
    },
    indexHomeLoading: {
      handler() {
        if (this.indexHomeLoading === "empty") {
          this.actionHomeIndex();
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.actionIndex(this.favorites);
  },
  methods: {
    contentType(it) {
      return it.items[0].contentType.toLowerCase();
    },
    ...mapActions("home", {
      actionHomeIndex: "index"
    }),
    ...mapActions("favorites", {
      actionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapMutations("content", {
      setEntityContent: "SET_ENTITY"
    })
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
